import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import AboutPage from './pages/about';
import Footer from './components/Footer';
import TeamPage from './pages/team';
import Anthony from './pages/anthony';
import TermsPage from './pages/terms';
import Services from './components/Services';
import Baba from './pages/baba';
import Daniel from './pages/daniel';
import Service1 from './pages/service1';
import Service2 from './pages/service2';
import Service3 from './pages/service3';
import Service4 from './pages/service4';
import Service5 from './pages/service5';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/about/#services" component={Services} exact />
        <Route path="/team" component={TeamPage} exact />
        <Route path="/terms" component={TermsPage} exact />
        <Route path="/anthony" component={Anthony} exact />
        <Route path="/baba" component={Baba} exact />
        <Route path="/daniel" component={Daniel} exact />
        <Route path="/civil litigation and breach of contract disputes" component={Service1} exact />
        <Route path="/corporate immigration" component={Service2} exact />
        <Route path="/private immigration and naturalisation" component={Service3} exact />
        <Route path="/Debt Recovery" component={Service4} exact />
        <Route path="/fees and funding" component={Service5} exact />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
