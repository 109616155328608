import React, { useState, useEffect } from 'react';
import { Nav, NavbarContainer, MobileIcon, NavMenu, NavLinks, NavLinksc, NavItem, NavLogo } from './NavbarElem4';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { NavC } from '../Navbar/NavbarElem';

const Navbar2 = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)
    const changeNav = () => {
        if (window.scrollY >= 50) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome} > </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinksc to='/' scrollNav={scrollNav} onClick={toggleHome}>Home</NavLinksc>
                        </NavItem>
                        <NavItem>
                            <NavLinksc to="/about" scrollNav={scrollNav}>About</NavLinksc>
                        </NavItem>
                        <NavItem>
                            <NavLinksc to="about/#services" scrollNav={scrollNav} >Services</NavLinksc>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="team" scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact='true' offset={-80}>Our Team</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavC href='mailto:info@anthonyogunfeibosolicitors.com' scrollNav={scrollNav} >Contact Us</NavC>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar2
