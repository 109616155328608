import styled from 'styled-components';

export const AboutContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    // margin-top: 80px;
    height: 800px;
    position: relative; 
    z-index: 1;
`;

export const AboutContent = styled.div`
    z-index: 3;
    width: 74vw;
    position: absolute;
    padding: 8px 24px;
    display: flex; 
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 90vw;

    }

    @media screen and (max-width: 480px) {
        width: 90vw;
    }

`
export const AboutH1 = styled.h1`
    color: #000;
    font-size: 4rem;
    line-height: 5rem;
    display: block;
    margin: auto;
    text-align: center;
    border-bottom: 10px solid #CF142B;

    @media screen and (max-width: 768px) {
        line-height: 5rem;
    }

    @media screen and (max-width: 480px) {
        // font-size: 32px;
    }
`

export const AboutP = styled.p`
    margin-top: 70px; 
    margin-bottom: 5px; 
    color: #000;
    font-size: 18px; 
    text-align: justify;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        overflow-wrap: break-word;
        
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
`
export const AboutBtnWrapper = styled.div`
        margin-top: 32px;
        margin-left: 5px;
        display: flex;
        flex-direction: row; 
        align-items: center; 

`