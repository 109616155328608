export const LawyersOne = {
    id: 'anthony',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Anthony Ogunfeibo',
    description: 
    <span style={{ whiteSpace: 'pre-wrap' }}>
        {
            `Our principal Mr Anthony Ogunfeibo is a solicitor of 25 years post admission experience in England and Wales. \n\nHe graduated with a degree in Biology from the University of Lagos in 1982 after which he continued his education in England where he obtained his law degree from the University of London. Anthony was called to the English Bar at Lincoln’s Inn in 1989 and admitted as a Solicitor of the Supreme Court of England and Wales in 1995. \n\nHe has during his years of practice, advised and acted for clients from all over the world and this has given him considerable experience of foreign legal jurisdictions and international trade, finance, and commercial law. \n\nAnthony is also fluent in French and has a working knowledge of Italian.
            `
        }
    </span>,

    buttonLabel: 'Back',
    imgStart: false,
    img: require('../assets/ao_bg.jpg').default,
    alt: 'ao',
    dark: true,
    primary: true,
    darkText: true,
}

export const LawyersTwo = {
    id: 'baba',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Babatunde Dada',
    description: 
    <span style={{ whiteSpace: 'pre-wrap' }}>
    {
        `The Consultant Mr. Babatunde Dada is a solicitor admitted in both Nigeria and England and Wales with over 30 years legal experience having been first admitted in Nigeria in 1989 and thereafter in England and Wales in 2000. \n\nBefore obtaining his law degree he had qualified with a BA honours degree in History from the University of Benin in 1981. \n\nMr. Dada has practised extensively in Civil litigation advising, attending court and generally representing clients in Nigeria and the UK.`
    }
</span>,
        buttonLabel: 'Back',
    imgStart: false,
    img: require('../assets/bd_bg.jpg').default,
    alt: 'ao',
    dark: true,
    primary: true,
    darkText: true,
}
export const LawyersThree = {
    id: 'daniel',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Daniel Ijebuka Okoro',
    description: 
    <span style={{ whiteSpace: 'pre-wrap' }}>
    {
        `Mr Daniel Ijebuka Okoro is a barrister and solicitor of the Supreme Court of Nigeria and solicitor of the Senior Courts of England & Wales.
\nHe graduated from the Univerity of Calabar in 1997 and was called to Bar in Nigeria in 2000. In 2010 he was admitted to the roll of solicitors in England & Wales.
\nMr Okoro practiced as a barrister and solicitor of the Supreme Court of Nigeria for 8 years before migrating to the UK. He has practised as a solicitor in the UK for 10 years and has a total of 18 years experience as a lawyer in both jurisdictions.`
    }
</span>,
        buttonLabel: 'Back',
    imgStart: false,
    img: require('../assets/io_bg.jpg').default,
    alt: 'ao',
    dark: true,
    primary: true,
    darkText: true,
}
