import React from 'react'
import { TermsContainer, TermsContent, TermsH1, TermsP } from './TermsElem';

var link1 = <a href="https://www.legalombudsman.org.uk">Legal Ombudsman</a>;
var link2 = <a href="https://www.sra.org.uk/">SRA</a>;

const Terms = () => {



    return (
        <TermsContainer>
            <TermsContent>
                <TermsH1>
                    Complaints Procedure
                </TermsH1>
                <TermsP>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {`Anthony Ogunfeibo is committed to providing a high standard of legal service to all its clients. If, however, you are not satisfied with any aspect of our service, complaint handling procedures are in place to deal with your concerns.

If at any point you become unhappy or concerned about our service, you should inform us immediately, so that we can do our best to resolve the problem.

In the first instance it may be helpful to contact the person who is working on your case to discuss your concerns and we will do our best to resolve any issues at this stage.

However if your concerns are still not resolved and you would like to make a formal complaint,  then a copy of our full written Complaints Policy and Procedure is available on request.

If there is cause for a complaint, we would request that complaints are made in writing and addressed to:

\nA:   Anthony Ogunfeibo
      Complaints Partner Anthony Ogunfeibo & Co
      352 Camberwell New Road
      London
      SE5 0RW
      England
\nW:  www.anthonyogunfeibo.co.uk
\nE:   info@anthonyogunfeibo.co.uk
\nT:   020 7501 9898

Please note once you have gone through our internal complaints procedures and you are not happy with our final response and suggested resolution, you are able to take your complaint further to the relevant independent bodies, namely the `}{link1}{` and the Solicitors Regulation Authority `}{link2}{`. 
`}
                    </span>
                </TermsP>
            </TermsContent>
        </TermsContainer>

    )
}

export default Terms
