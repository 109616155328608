import React, { useState, useEffect } from 'react';
import { Nav, NavbarContainer, MobileIcon, NavMenu, NavLinks, NavItem, NavC, NavLogo } from './NavbarElem3';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll'

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome} > </NavLogo>
                    <MobileIcon scrollNav={scrollNav} onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='/' scrollNav={scrollNav} onClick={toggleHome}>Home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/about" scrollNav={scrollNav}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="about/#services" scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact='true' offset={-80}>Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/team" scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact='true' offset={-80}>Our Team</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavC href='mailto:info@anthonyogunfeibosolicitors.com' scrollNav={scrollNav} >Contact Us</NavC>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </>
    )
}

export default Navbar
