import React, {useState} from 'react'
import Navbar from '../components/Navbar'
import Service from '../components/Services/Service'
import Sidebar from '../components/Sidebar'
import { ServiceFive } from '../data/ServiceData'

const Service5 = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Service {...ServiceFive}/>
        </>
    )
}

export default Service5
