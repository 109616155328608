import React, {useState} from 'react'
import Navbar from '../components/Navbar4';
import Sidebar from '../components/Sidebar';
import Team from '../components/Team';

const TeamPage = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {setIsOpen(!isOpen)
    }
    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Team />
        </>
    )
}

export default TeamPage
