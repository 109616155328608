import React from 'react';
import styled from 'styled-components';
import Icon1 from '../../assets/ao_bg.jpg';
import Icon2 from '../../assets/bd_bg.jpg';
import Icon3 from '../../assets/io_bg.jpg';
import { Link as LinkR } from 'react-router-dom';


const TeamContainer = styled.div`
min-height: 800px;
display: flex; 
flex-direction: column;
justify-content: center; 
align-items: center;
margin-top: 40px;

@media screen and (max-width: 1000px) {
    min-height: 800px;
    padding-top: 50px;        
    padding-bottom: 50px;   
    margin-top: 80px;
     
}


@media screen and (max-width: 768px) {
    min-height: 800px;
    padding-top: 50px;        
    padding-bottom: 50px;        
}

@media screen and (max-width: 480px) {
    min-height: 800px;
    padding-top: 50px;    
    padding-bottom: 50px;    
}
`

const TeamWrapper = styled.div`
min-width: 90%;
margin: 0 auto; 
display: grid;
grid-template-columns: 1fr 1fr 1fr;
align-items: center; 
grid-gap: 16px;
padding: 0 50px;


@media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
}

@media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
    margin: 0;
}
`

const TeamCard = styled(LinkR)`
background: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
padding: 20px 0;
margin: 5px 0px;
cursor: pointer;
text-decoration: none;
`

const TeamPic = styled.img`
height: 200px;
width: 200px;
margin-bottom: 60px;
object-fit: cover;
border-radius: 50%;

&:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    height: 250px;
    width: 250px;
}


`

const TeamH1 = styled.h1`
font-size: 4rem;
color: #000;
margin-bottom: 70px;
margin-top: -30px;
line-height: 5rem;
display: block;
text-align: center;
border-bottom: 10px solid #CF142B;

@media screen and (max-width: 768px) {
    margin-top: auto;
    margin-bottom: 50px;
    font-size: 3rem;
}
@media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 80px;
}
`
const TeamH2 = styled.h2`
font-size: 1.2rem;
padding: 10px;
// margin-top: -10px;
// margin-bottom: -10px;
letter-spacing: .5px;

@media screen and (max-width: 768px) {
    font-size: 20px;
}

&:hover {
    transition: all 0.3s ease-in-out; 
    margin-bottom: 5px;
    margin-top: -5px;
    // color: #000d1a;

}

`
const TeamLink = styled(LinkR)`
text-decoration: none;
color: #000;

&:hover {
    transition: all 0.2s ease-in-out; 
    color: #000d1a;

}
`

const Team = () => {
    return (
        <TeamContainer id='team'>
            <TeamH1>YOUR LAWYERS</TeamH1>
            <TeamWrapper>
                <TeamCard to="/anthony" >
                    <TeamPic src={Icon1} />
                    <TeamH2 >
                        <TeamLink to="/anthony" >
                            ANTHONY OGUNFEIBO
                        </TeamLink>
                    </TeamH2>
                </TeamCard>
                <TeamCard to="/baba" >
                    <TeamPic src={Icon2} />
                    <TeamH2>
                        <TeamLink to="/baba" >
                            BABATUNDE DADA
                        </TeamLink>
                        </TeamH2>
                </TeamCard>
                <TeamCard to="/daniel" >
                    <TeamPic src={Icon3} />
                    <TeamH2>
                        <TeamLink to="/daniel" >
                            DANIEL IJEBUKA OKORO
                        </TeamLink>
                        </TeamH2>
                </TeamCard>
            </TeamWrapper>
        </TeamContainer >

    )
}

export default Team
