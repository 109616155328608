import React from 'react'
import { AboutContainer, AboutContent, AboutH1, AboutP } from './AboutElem';


const About = () => {
    return (
        <AboutContainer id="about">
            <AboutContent>
                <AboutH1>
                    ABOUT US
                </AboutH1>
                <AboutP>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {`Anthony Ogunfeibo & Co. is a commercial law firm in London that provides a range of legal services to clients across the world.

Our expert lawyers have experience and skill in providing our clients with innovative and performance driven solutions in all our areas of work, including:

•  All Aspects of Civil Litigation and Breach of Contract Disputes
•  Corporate Immigration
•  Private Immigration

Our highly experienced litigation lawyers can also offer advice and representation to clients in proceeding before International Arbitration Tribunals.

Please use our contact form to provide us with details of your request for any one of our services.

`}
                    </span>

                </AboutP>
            </AboutContent>
        </AboutContainer>

    )
}

export default About
