import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../ButtonElem';

const ServiceContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#fff')};
    
    @media screen and (max-width: 1000px) {
        padding: 100px 0;
    }
`

const ServiceWrapper = styled.div`
display: flex;
z-index: 1;
min-height: 900px;
margin-right: auto;
margin-left: auto;
padding: 0 10px;
justify-content: center;

@media screen and (max-width: 768px) {
    min-height: 600px;
}
`

const ServiceRow = styled.div`
display: flex;
align-items: center;

`

const TextWrapper = styled.div`
min-width: 1000px;
max-width:1000px;
padding-top: 0;
padding-bottom: 60px;

@media screen and (max-width: 1000px) {
    min-width: 90vw;
}

`

const TopLine = styled.h1`
color: #000;
font-size: 2.2rem;
font-weight: 700;
letter-spacing: 1px;
line-height: 3rem;
text-align: center;
text-transformation: uppercase;
margin-bottom: 50px; 
// padding-bottom: 20px;
border-bottom: 10px solid #CF142B;

@media screen and (max-width: 1000px) {
    line-height: 4rem;
}
@media screen and (max-width: 768px) {
    font-size: 2.5rem;
}

`

const Heading = styled.h1`
color: #000;
margin-top: 24px; 
margin-bottom: 24px; 
font-size: 2rem;
line-height: 1.1rem;
font-weight: 700; 
color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

@media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1rem;
}
@media screen and (max-width: 480px) {
    font-size: 1.5rem;
    line-height: 1rem;
}`

const Subtitle = styled.p`
margin-bottom: 35px;
font-size: 18px;
line-height: 21px;
text-align: justify;
color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

@media screen and (max-width: 768px) {
    font-size: 16px;
}
`

const BtnWrap = styled.div`
display: flex;
justify-content: center;
`

const ImgWrap = styled.div`
max-width: 350px;
height: 100%;
margin-top: -40px;

@media screen and (max-width: 1000px) {
    max-width: 300px;
}

`
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; 
`

const Service = ({ download, lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2 }) => {
    const [scrollNav, setScrollNav] = useState(false)
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <ServiceContainer>
                <ServiceWrapper>
                    <ServiceRow imgStart={imgStart}>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <Subtitle darkText={darkText}>{download}</Subtitle>
                            <BtnWrap>
                                <Button href="about/#services" scrollNav={scrollNav} smooth={true} duration={500} spy={true} exact='true' offset={-80}>{buttonLabel}</Button>
                            </BtnWrap>
                        </TextWrapper>
                    </ServiceRow>
                </ServiceWrapper>
            </ServiceContainer>
        </>
    )
}

export default Service


