import React from 'react';
import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';

const ServicesContainer = styled.div`
min-height: 800px;
display: flex; 
flex-direction: column;
justify-content: center; 
align-items: center;
background: #000d1a;

@media screen and (max-width: 768px) {
    min-height: 800px;
    padding-top: 50px;        
    padding-bottom: 50px;        
}

@media screen and (max-width: 480px) {
    min-height: 800px;
    padding-top: 50px;    
    padding-bottom: 50px;    
}
`

const ServicesWrapper = styled.div`
max-width: 1400px;
margin: 0 auto; 
display: grid;
grid-template-columns: 1fr 1fr;
align-items: center; 
grid-gap: 16px;
padding: 0 50px;


@media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
}
`

const ServicesCard = styled.div`
background: #fff;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
text-align: center;
border-radius: 10px;
height: 150px;
padding: 20px;
margin: 5px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;
cursor: pointer;

&:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}


}
`

const ServicesH1 = styled.h1`
font-size: 3rem;
color: #fff;
margin-bottom: 50px;
line-height: 5rem;
margin-top: -30px;
display: block;
text-align: center;
border-bottom: 10px solid #CF142B;

@media screen and (max-width: 768px) {
    margin-top: auto;
    margin-bottom: 50px;
}
@media screen and (max-width: 480px) {
    font-size: 2rem;
    margin-top: 80px;
}
`
const ServicesH2 = styled.h2`
font-size: 1.5rem;
`

const ServiceLink = styled(LinkR)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 4rem 2.5rem;
    cursor: pointer; 

`

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>OUR SERVICES</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesH2>
                        <ServiceLink to="/civil litigation and breach of contract disputes">
                            CIVIL LITIGATION {`&`} BREACH OF CONTRACT DISPUTES
                        </ServiceLink>
                    </ServicesH2>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>
                        <ServiceLink to="/corporate immigration">
                            CORPORATE IMMIGRATION
                        </ServiceLink> 
                    </ServicesH2>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>
                        <ServiceLink to="/debt recovery">
                            DEBT RECOVERY
                        </ServiceLink> 
                    </ServicesH2>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>
                        <ServiceLink to="/private immigration and naturalisation">
                            PRIVATE IMMIGRATION {`&`} NATURALISATION
                        </ServiceLink> 
                    </ServicesH2>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>
                        <ServiceLink to="/fees and funding">
                            FEES {`&`} FUNDING
                        </ServiceLink> 
                    </ServicesH2>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer >
    )
}

export default Services
