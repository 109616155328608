import PDF from '../assets/Anthony_Ogunfeibo_Solicitors_Debt_Recovery_Cost_of_Service.pdf';
import PDF2 from '../assets/Anthony_Ogunfeibo_Solicitors_Immigration_Fees.pdf';
import styled from 'styled-components';

const Download = styled.a`
    text-decoration: none;
    color: #CF142B;
`

export const ServiceOne = {
    id: 'civil litigation',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'CIVIL LITIGATION & BREACH OF CONTRACT DISPUTES',
    description:
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {
                `Our Civil Litigation work and expertise covers a wide area including but not limited to the following:

•   Claims for Possession by Landlords against Tenants in County Court
•   Breach of Contractual Terms and Conditions 
•   Damages and Compensation Claims for Property Disrepair
•   Damages and Compensation Claims for Accidents at Work
•   Personal Injury & Medical Negligence Claims
•   Breach of Commercial Agreements
•   Probate Claims
•   Matrimonial Property Disputes
•   Enforcement of Consumer Rights
`
            }
        </span>,
    buttonLabel: 'Back',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: true,
}
export const ServiceTwo = {
    id: 'Corporate Immigration',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'CORPORATE IMMIGRATION',
    description:
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {
                `Our Corporate Immigration work covers the following areas:

•   Applications for Sponsorship Licences
•   Applications for Certificates of Sponsorship 
•   Applications for Pre-Licence Error Correction after Refusal decision 
•   Review and Renewal of Applications after a previous refusal decision 
•   Applications for Judicial Review after refusal decision 
`
            }
        </span>,
    buttonLabel: 'Back',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: true,
}
export const ServiceThree = {
    id: 'Private Immigration',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'PRIVATE IMMIGRATION & NATURALISATION',
    description:
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {
                `We deal with all types of Individual and  Family Immigation and Naturalisation applications including but not limited to the following:

•   Application for Entry Clearance and Visas  to the UK
•   Application to the UK for Family Reunion
•   Applications for Leave to Remain in the UK
•   Applications for Indefinite Leave in the UK
•   Student Visa applications 
•   Applications for Naturalisation as British citizens
•   Appeals against refusal decisions on Visa Applications 
•   Appeals Against In-Country Applications  for Leave to Remain
•   Appeals Against Applications for Indefinite Leave to Remain
•   Work Permit Applications 
•   Start-Up Visa Applicatios
•   Innovator Visa Applications 
•   Partner and Parent Route Applications 
`
            }
        </span>,
    buttonLabel: 'Back',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: true,
    download: <span> You can find out about our fees <Download href={PDF2} download="Immigration Fees" target='_blank'> here </Download> </span>,

}
export const ServiceFour = {
    id: 'debt recovery',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'DEBT RECOVERY',
    description:
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {
                `Our Debt Recovery Department deals with the following areas of work:

•   Recovery of Debt on unpaid invoices
•   Private Debt Collection 
•   Business Debt Collection 
•   Alternative Dispute Resolution on unpaid debts
•   Negotiation and Restructuring of Unpaid Debt
•   Letters Before Claim against Private and Commercial Debtors
•   Commencement of Legal Proceedings for Debt Recovery
•   Civil Enforcement of Judgment Debt
`
            }
        </span>,
    buttonLabel: 'Back',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: true,
    download: <span> You can find out about our fees <Download href={PDF} download="Debt Recovery Fees" target='_blank'> here </Download> </span>,
}
export const ServiceFive = {
    id: 'Fees & Funding',
    lightBg: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'FEES & FUNDING',
    description:
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {
                `As an essential element of its service Anthony Ogunfeibo will advise you on the options available to you for funding your case.

Private Clients:

At your first meeting you will be told how we calculate our charges. Whenever possible we will give you a detailed breakdown of the estimated total costs, whether these are our own fees or for an external resource such as a barrister or expert. As with all solicitors, Anthony Ogunfeibo requires your authority to make these payments, known as disbursements, to other people on your behalf.

In litigation matters you may be able to recover your costs from the opposing party if you win your case.


Legal Expenses Insurance:
 
Many household and motor insurance policies include cover for legal costs. It may be worth checking the policy wording of your existing insurances before your first appointment to find out if this is an option in your case.

Please contact us so that we can assess what funding may be available to you.`
            }
        </span>,
    buttonLabel: 'Back',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: true,
}