import React, { useState } from 'react';
import { HeroContainer, HeroContent, HeroH1, HeroP, ArrowForward, ArrowRight, HeroBtnWrapper } from './HeroElem';
import { Button } from '../ButtonElem';


const Hero = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    };


    return (
        <HeroContainer>
            <HeroContent>
                <HeroH1>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {`ANTHONY\nOGUNFEIBO \nSOLICITORS`}
                    </span>
                </HeroH1>
                <HeroP>
                    Professional Law Practice Since 1996
                </HeroP>
                <HeroBtnWrapper>
                    <Button href='mailto:info@anthonyogunfeibosolicitors.com' onMouseOver={onHover} onMouseLeave={onHover}
                    // primary="true" dark="false"
                    >
                        Get In Touch {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default Hero
