import React, { useState } from 'react';
import Navbar2 from '../components/Navbar2';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Services from '../components/Services';
import About from '../components/About';

const AboutPage = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar2 toggle={toggle} />
            <About />
            <Services />
            {/* <InfoCards {...homeObjOne}/> */}
        </>
    )
}

export default AboutPage
