import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'

export const HeroContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative; 
    z-index: 1;

    // :before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0; 
    //     right: 0;
    //     bottom: 0;
    //     background: linear-gradient(
    //         180deg, 
    //         rgba(0,0,0,0.2) 0%, 
    //         rgba(0,0,0,0.6) 100%
    //         ), 
    //         linear-gradient(
    //         180deg, 
    //         rgba(0,0,0,0.2) 0%, 
    //         transparent 100%);
    //     z-index: 2; 
    // }
`;

export const HeroContent = styled.div`
    z-index: 3;
    // max-width: 1500px;
    width: 90vw;
    position: absolute;
    padding: 8px 24px;
    display: flex; 
    flex-direction: column;
    align-items: left;
`
export const HeroH1 = styled.h1`
    color: #000;
    font-size: 120px;
    letter-spacing: 1px;
    line-height: 7.5rem;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 60px;
        line-height: 5rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 25px; 
    margin-bottom: 5px; 
    margin-left: 5px; 
    color: #000;
    font-size: 20px; 
    text-align: left;
    max-width: 1000px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;

`
export const HeroBtnWrapper = styled.div`
        margin-top: 32px;
        margin-left: 5px;
        display: flex;
        flex-direction: row; 
        align-items: center; 

`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`