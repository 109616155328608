import styled from 'styled-components';

export const TermsContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    min-height: 1100px;
    position: relative; 
    z-index: 1;

    @media screen and (max-width: 1200px) {
        height: 160vh;
    }
`;

export const TermsContent = styled.div`
    z-index: 3;
    width: 70vw;
    position: absolute;
    padding: 8px 24px;
    display: flex; 
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 90vw;
    }

    @media screen and (max-width: 480px) {
        width: 90vw;
    }

`
export const TermsH1 = styled.h1`
    color: #000;
    font-size: 4rem;
    text-align: center;
    line-height: 5rem;
    display: block;
    margin: auto;
    text-align: center;
    border-bottom: 10px solid #CF142B;

`

export const TermsP = styled.p`
    margin-top: 55px; 
    margin-bottom: 5px; 
    color: #2F2F2F;
    font-size: 18px; 
    letter-spacing: .5px;
    text-align: justify;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
`
export const TermsBtnWrapper = styled.div`
        margin-top: 32px;
        margin-left: 5px;
        display: flex;
        flex-direction: row; 
        align-items: center; 

`