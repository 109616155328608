import React, {useState} from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Member from '../components/Team/Member'
import { LawyersThree } from '../data/CardData'

const Daniel = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {setIsOpen(!isOpen)
    }
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Member {...LawyersThree} />
        </>
    )
}

export default Daniel
