import styled from 'styled-components'
import {Link} from 'react-router-dom';

export const Button = styled.a`
    background: ${({primary}) => (primary ? '#CF142B' : '#CF142B')};
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none; 
    border: none;
    cursor: pointer; 
    display: flex;
    justify: center; 
    align-items: center;
    text-decoration: none; 
    transition: all 0.2s ease-in-out; 

    &:hover {
        transition: all 0.5s ease-in-out; 
        border-radius: ${({round}) => (round ? '0' : '50px')};
        margin: 0 5px;

    }

`