import React from 'react'
import styled from 'styled-components';
import { Button } from '../ButtonElem';

const MemberContainer = styled.div`
    color: #fff;
    margin-top: 20px;

    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#fff')};
    
    @media screen and (max-width: 1000px) {
        padding: 100px 0;
    }
`

const MemberWrapper = styled.div`
display: grid;
z-index: 1;
min-height: 900px;
margin-right: auto;
margin-left: auto;
padding: 0 10px;
justify-content: center;

@media screen and (max-width: 768px) {
    min-height: 600px;
}
`

const MemberRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`

const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px; 
grid-area: col1;
`

const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px; 
grid-area: col2;
display: flex; 
justify-content: center;
align-items: center;

@media screen and (max-width: 768px) {
    display: none;
}
`

const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;
`

const TopLine = styled.h1`
color: #000;
font-size: 3rem;
line-height: 5rem;
font-weight: 700;
letter-spacing: 1px;
text-transformation: uppercase; 
marging-bottom: 16px; 
border-bottom: 10px solid #CF142B;

@media screen and (max-width: 1000px) {
    line-height: 4rem;
}
@media screen and (max-width: 768px) {
    font-size: 2.5rem;
}

`

const Heading = styled.h1`
color: #000;
margin-top: 24px; 
margin-bottom: 24px; 
font-size: 2rem;
line-height: 1.1;
font-weight: 700; 
color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

@media screen and (max-width: 480px) {
    font-size: 1.5rem;
}`

const Subtitle = styled.p`
max-width: 550px;
margin-bottom: 35px;
font-size: 18px;
line-height: 21px;
text-align: justify;
color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

const BtnWrap = styled.div`
display: flex;
justify-content: flex-start;
`

const ImgWrap = styled.div`
max-width: 350px;
height: 100%;
margin-top: -40px;

@media screen and (max-width: 1000px) {
    max-width: 300px;
}

`

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; 
`

const Member = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2 }) => {
    return (
        <>
            <MemberContainer>
                <MemberWrapper>
                    <MemberRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                                <BtnWrap>
                                    <Button href="/team">{buttonLabel}</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </MemberRow>
                </MemberWrapper>
            </MemberContainer>
        </>
    )
}

export default Member


