import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
background-color: #101522;
`

const FooterWrap = styled.div`
padding: 28px 24px;
display: flex; 
flex-direction: column;
justify-content: center;
align-items: center; 
max-width: 1100px;
margin: 0 auto;
`

const FooterLinksContainer = styled.div`
display: flex;
justify-content: center;

@media screen and (max-width:820px) {
    padding-top: 32px;
}
`

const FooterLinksWrapper = styled.div`
display: flex;

@media screen and (max-width: 820px) {
    flex-direction: column; 
}
`

const FooterLinkItems = styled.div`
display: flex; 
flex-direction: column; 
align-items: center;
margin: 16px;
text-align: left; 
width: 300px;
box-sizing: border-box;
color: #fff;

@media screen and (max-width: 420px){
    margin: 0;
    padding: 10px; 
    width: 100%;
}
`
const FooterLinkTitle = styled.h1`
font-size: 14px;
margin-bottom: 16px;
`
const FooterLink = styled(Link)`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;
text-align: center;

&:hover {
    color: #CF142B;
    transition: 0.3s ease-out;
}
`
const FooterELink = styled.a`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;
text-align: center;

&:hover {
    color: #CF142B;
    transition: 0.3s ease-out;
}
`
const FooterText = styled.p`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;
text-align: center;
`
const WebsiteRights = styled.small`
    color: #fff;
    // margin-bottom: 16px;
`

const SocialMedia = styled.section`
max-width: 1000px;
width: 100%;
`
const SocialMediaWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
max-width: 1000px;
margin: 40px auto 0 auto;

@media screen and (max-width: 820px) {
    flex-direction: column;
}
`

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <div max-width="275px" max-height="163px"><div position="relative" padding-bottom="59.1%" height="auto" overflow="hidden"><iframe frameborder="0" scrolling="no" allowTransparency="true" src="https://cdn.yoshki.com/iframe/55845r.html" border="0px" margin="0px" padding="0px" backgroundColor="transparent" top="0px" left="0px" width="100%" height="100%" position="absolute"></iframe></div></div>
                        <FooterLinkItems>
                            <FooterLinkTitle>Pages</FooterLinkTitle>
                            <FooterLink to="/about">What We Do</FooterLink>
                            <FooterLink to="/about">Our Services</FooterLink>
                            <FooterLink to="/team">Our Team</FooterLink>
                            <FooterLink to="/terms">Complaints Procedure</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact Us</FooterLinkTitle>
                            <FooterELink href='mailto:info@anthonyogunfeibosolicitors.com'>E: info@aogunfeibosolicitors.co.uk</FooterELink>
                            <FooterText>P: +44 20 7501 9898</FooterText>
                            <FooterText>SRA Number: 199852</FooterText>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Address</FooterLinkTitle>
                            <FooterText>
                                <span style={{ whiteSpace: 'pre-wrap' }} >
                                    {`352 Camberwell New Road \nLondon \nSE5 0RW \nEngland`}
                                </span>
                            </FooterText>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <WebsiteRights>Anthony Ogunfeibo Solictors © {new Date().getFullYear()} All rights reserved</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
